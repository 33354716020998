import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private local: LocalStorageService, private session: SessionStorageService) { }


  async set(key, value) {
    return this.session.set(key, value, 31, 'm');
  }

  remove(key) {
    this.session.remove(key);
  }

  get(key) {
    return this.session.get(key);
  }

  clear() {
    this.local.clear();
  }
}
