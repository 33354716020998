import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  marker_render_1  :  Subject<boolean>;
  marker_render_2  :  Subject<boolean>;
  marker_render_3  :  Subject<boolean>;
  marker_render_4  :  Subject<boolean>;
  marker_render_5  :  Subject<boolean>;
  marker_render_6  :  Subject<boolean>;
  marker_render_7  :  Subject<boolean>;
  marker_render_8  :  Subject<boolean>;
  marker_render_9  :  Subject<boolean>;
  marker_render_10 :  Subject<boolean>;
  marker_render_11 :  Subject<boolean>;
  adreess: Subject<any>;
  login_data: any
  access_token = ''
  index
  is_user_from_login = false;

  constructor() {
    this.marker_render_1   =  new Subject<boolean>();
    this.marker_render_2   =  new Subject<boolean>();
    this.marker_render_3   =  new Subject<boolean>();
    this.marker_render_4   =  new Subject<boolean>();
    this.marker_render_5   =  new Subject<boolean>();
    this.marker_render_6   =  new Subject<boolean>();
    this.marker_render_7   =  new Subject<boolean>();
    this.marker_render_8   =  new Subject<boolean>();
    this.marker_render_9   =  new Subject<boolean>();
    this.marker_render_10  =  new Subject<boolean>();
    this.marker_render_11  =  new Subject<boolean>();
    this.adreess = new Subject<any>();
  }

  duration_hudler(time_stamp, type){
    var elapsed_time;
    if(type == 'gettime') elapsed_time = Date.now() - new Date(time_stamp).getTime();
    else elapsed_time = Date.now() - time_stamp;
   var second: any;
   var minute: any;
   var hour: any;
   var day: any;
   var month: any;
   var year: any;
   const seconds = elapsed_time/1000;
   if(seconds > 0) {
    second = `${Math.floor(seconds)} sec`;
    //minutes handler
    const minutes = seconds/60;
      if(minutes >= 1) {
       minute = `${Math.floor(minutes)} min`;
        //hour handler
        const hours = minutes/60;
        if(hours >= 1) {
          const remain_min = minutes % 60;
          hour = `${Math.floor(hours)} hr ${Math.floor(remain_min)} min`;
          //day handler
          const days = hours/24;
          if(days >= 1){
            const remain_hour = hours % 24;
            day = `${Math.floor(days)} days ${Math.floor(remain_hour)} hr`;
            //month handler
            const months = days/30;
            if(months >= 1){
              const remain_day = days % 30;
              month = `${Math.floor(months)} month ${Math.floor(remain_day)} days`;
              //year handler
              const years = months/12;
              if(years >= 1){
                const remain_month = months % 12;
                year = `${Math.floor(years)} year ${Math.floor(remain_month)} month`;
                return year;
              } else return month;
            } else return day;
          } else return hour;
        } else return minute;
      } else return second;
   } 
  }

  //large array slicer into 10 smaller arrays
  array_slicer(array){ 
    var sliced_array = [];
    const array_length = array.length;
    const stack = Math.floor(array_length/10);
    const remain = array_length % 10;
    //iterate through
    for(let i = 0; i < 10; i++) sliced_array.push(array.slice(i * stack, (stack * (i + 1))));
    if(remain > 0) sliced_array.push(array.slice(array_length - remain, array_length));
    return sliced_array;
  }
  
  //remove item from an array
  removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  //point out index of a value
  get_index(arr, value){
    var index = arr.indexOf(value);
    return index;
  }

  async getReverseGeocodingData(lat, lng, party) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': latlng },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == google.maps.GeocoderStatus.OK) {
          //console.log(results[3].formatted_address);
            var address = {
              address: results[3].formatted_address,
              party: party
             }
            this.adreess.next(address);
        }
    });
}

calculateDistance(lat1, lng1, lat2, lng2) {
  var p = 0.017453292519943295;    // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p)) / 2;
  return 12742 * Math.asin(Math.sqrt(a));  //2 * R; R = 6371 km
}

 get_time(d){
  const time = new Date(d);
  var hours;
  var minutes;
  var hour = time.getHours();
  var minute = time.getMinutes();
  if(hour < 10) hours = `0${hour}`;
  else hours = hour;
  if(minute < 10) minutes = `0${minute}`;
  else minutes = minute;
  return `${hours}:${minutes}`;
 }

 get_duration(time_1, time_2){
  var response = {
    word: '',
    value: 0
  }
  var hours;
  var minutes;
  const t_1 = new Date(time_2);
  const t_2 = new Date(time_1);
  var time = t_1.getTime() - t_2.getTime();
  const min = time/60000;
  const hour = min/60;
  if(hour >= 1) {
    hours = Math.floor(hour);
    minutes = hour % 60;
    return response = {
      word: `${hours} hr ${minutes} min`,
      value: hours * 60 + minutes
    }
} else {
  minutes = Math.floor(min);
  return response = {
    word: `${minutes} min`,
    value: minutes
  }
}
 }

 getDaysArray = function(start, end) {
  for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate() + 1)){
      arr.push(new Date(dt).toISOString().split('T')[0] + 'T00:00:00');
  }
  return arr;
};

 getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  return diff;
}
}