import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { HelpersService } from './helpers.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { HttpClientService } from './http-client.service';
import { report, users_table, driver_table, get_devices } from './query.js';
import { ToastController } from '@ionic/angular';


const REFRESH_TOKEN_TIME = 'time-access-token';
const ACCESS_TOKEN = '5678596073';
const USER_DATA = '67458967405';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);
    // Init with null to filter out the first value in a guard!
    isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentAccessToken = null;
    loading_controller = false;
    login_failde = false;
   
    async presentToast(text){
      const toast = await this.toastCtrl.create({
        message: text,
        duration: 3000
      });
      toast.present();
    }
    refresh_interval
    user_plan = new Subject();
  constructor(
    private platform: Platform,
    private helper: HelpersService,
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private toastCtrl: ToastController,
    private api_calls: HttpClientService
  ) { 
    this.platform.ready().then(() => {
      this.loadToken();
    });
  }

  
// Load accessToken on startup
async loadToken() {
  const session_time: any = await this.storage.get(REFRESH_TOKEN_TIME);
  if (session_time/1000 - 60 >  new Date().getTime()/1000) {
    this.isAuthenticated.next(true);
    this.router.navigateByUrl('/home');
  } else {
    this.isAuthenticated.next(false);
  }
}

  async login1(login: {username, password }) {
    const url = `${environment.authentication}`;
    this.loading_controller = true;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    this.http.post(url, login, httpOptions)
        .pipe(
          finalize(() => {
            
          })
        ).subscribe(async res => {
          const data: any = res;
          if(data && data.data){
             this.helper.login_data = data.data;  //863844055490475
             this.helper.access_token = data.token;
             this.helper.is_user_from_login = true;
             var session_start_time = new Date().getTime() + 1800000;
             await this.storage.set(REFRESH_TOKEN_TIME, session_start_time);
             await this.storage.set(ACCESS_TOKEN, this.helper.access_token);
             await this.storage.set(USER_DATA, this.helper.login_data);
             this.user_plan.next(true);
             this.api_calls.getLast_device_position(this.helper.login_data.id).subscribe(response =>{
                 this.api_calls.positions = response;
                 this.api_calls.get_report(this.helper.login_data.id).subscribe(repo => {
                 this.api_calls.reports = repo;
                 this.api_calls.reports.reverse();
                 for(var i = 0; i<this.api_calls.reports.length; i++) this.api_calls.reports[i].date = new Date(this.api_calls.reports[i].date).toUTCString();
                 report.next(true);
                 this.api_calls.get_users(this.helper.login_data.id).subscribe(users => {
                     this.api_calls.users = users;
                     this.api_calls.users.push(this.helper.login_data);
                     this.api_calls.users.reverse();
                     for(var i = 0; i<this.api_calls.users.length; i++) this.api_calls.users[i].rdate = new Date(this.api_calls.users[i].rdate).toUTCString().split('GMT')[0] + 'EAT';
                     users_table();
                     this.api_calls.getDevice(this.helper.login_data.id).subscribe(dev => {
                         this.api_calls.devices = dev;
                         this.api_calls.devices.reverse();
                         get_devices.next(true);
                         this.api_calls.get_drivers(this.helper.login_data.id).subscribe(driv =>{
                             this.api_calls.drivers = driv;
                             this.api_calls.drivers.reverse();
                             for(var i = 0; i<this.api_calls.drivers.length; i++) this.api_calls.drivers[i].rdate = new Date(this.api_calls.drivers[i].rdate).toUTCString().split('GMT')[0] + 'EAT';
                             driver_table();
                             this.loading_controller = false;
                             this.isAuthenticated.next(true);
                             this.router.navigate(['/home']);
                         })  
                    })
                 })
              })
           })
          } else {
            this.loading_controller = false;
          }
        });
  }

  async logout(){
    await this.storage.set(REFRESH_TOKEN_TIME, 0);
    await this.storage.set(ACCESS_TOKEN, '');
    await this.storage.set(USER_DATA, {});
    this.isAuthenticated.next(false);
    this.router.navigate(['/login']);
  }
}
