import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController, ToastController, AlertController  } from '@ionic/angular';
import { HelpersService } from './helpers.service';
import { finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { report, update, device_update, get_devices, fuel_price_set, users_table, user_update, driver_update, driver_table } from './query.js';

@Injectable({
  providedIn: 'root'
})

export class HttpClientService {

  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private helper: HelpersService,
  ) { }

  positions: any = [];
  devices: any = []
  device = new Subject();
  history = new Subject();
  refresh_form = new Subject();
  fuel_history = new Subject();
  reports: any = [];
  users: any = [];
  drivers: any = [];
  //helper Method
  async presentToast(text){
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000
    });
    toast.present();
  }

  async postData(driver_data: {uniqueId, plate, driver, model, manufacturer, chasis, engine, group, rdate, colo, devicestatus}) {
    const url = `${environment.device_api}`;
    const loading = await this.loadingCtrl.create({
      message: 'Creating device...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.post(url, driver_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            this.devices.reverse();
            this.devices.push(val);
            this.devices.reverse();
            this.refresh_form.next(true);
            device_update();
            get_devices.next(true);
            const link = {
                userId   : this.helper.login_data.id,
                deviceId : val.id
            }
            this.user_devce_link(link, 'S')
            this.presentToast('Device created Successfully!');
          } else {
            this.presentToast('Create device failed. Please try again');
          }
        });
   }

   async update_device(driver_data: { uniqueId, plate, driver, model, manufacturer, chasis, engine, color }, id) {
    const url = `${environment.device_api}/${id}`;
    const loading = await this.loadingCtrl.create({
      message: 'Updating device...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.put(url, driver_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            for(let i = 0; i < this.devices.length; i++) if(this.devices[i].deviceId == val.id) this.devices[i] = val;
            this.presentToast('Device Updated Successfully!');
          } else {
            this.presentToast('update device failed. Please try again');
          }
        });
   }

    getDevice(userId) : Observable<any>  {
    const url = `${environment.device_api}/${userId}`;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }),
    }
    
    return this.http.get(url, httpOptions)
   }

   async user_devce_link(user_device: { userId, deviceId }, from){
    const url = `${environment.user_device}`;
    const loading = await this.loadingCtrl.create({
      message: 'Creating device...',
    }); 
    if(from == 'V') await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type'  : 'application/json',
        'Authorization' : `Bearer ${this.helper.access_token}`
      }) 
    }

    this.http.post(url, user_device, httpOptions)
    .pipe(
      finalize(() => {
        loading.dismiss();
      })
    ).subscribe(res => {
      this.presentToast('User-Device linked Successfully!');
    })
   }

   delete_device(id){
     this.delete_confirm(id);
   }
  
   getLast_device_position (userId): Observable<any> {
    const url = `${environment.position_api}/${userId}`;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    return this.http.get(url, httpOptions)
   }

   get_device_position = (device_id, from, to) => {
     const url = `${environment.position_api}/${device_id},${from},${to}`;
     let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    this.http.get(url, httpOptions)
        .pipe(
          finalize(() => {
            
          })
        ).subscribe(response => {
           this.history.next(response);
        });
   }

   generate_report = async (report_data: {deviceID, from, to, device, user, info, reportType, date, based}) => {
    const url = `${environment.report_api}`;
    const loading = await this.loadingCtrl.create({
      message: 'Creating device...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.post(url, report_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            this.reports.reverse();
            this.reports.push(val);
            this.reports[this.reports.length - 1].date = new Date(`${this.reports[this.reports.length - 1].date}.000Z`).toUTCString();
            this.reports.reverse();
            update();
            report.next(true);
            this.presentToast('Report created Successfully!');
          } else {
            this.presentToast('Create report failed. Please try again');
          }
        });
   }

   get_report (id): Observable<any> {
    const url = `${environment.report_api}/${id}`;
     let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    return this.http.get(url, httpOptions)
   }

   delete_report = async (id) => {
    this.show_confirm(id);
   }

   show_confirm(id) {
    this.alertController.create({
      header: 'Zebegna',
      message: 'Do you want to delete this report?',
      backdropDismiss: false,
      buttons: [{
        text: 'No',
        role: 'cancel',
        handler: () => {
          console.log('delete report prevented!');
        }
      }, {
        text: 'Yes',
        handler: async () => {
          const ID = this.reports[id].id;
          const filename = this.reports[id].filepath.split('/');
          const url = `${environment.report_api}/${ID},${filename[4]}`;
          const loading = await this.loadingCtrl.create({
            message: 'Creating device...',
          }); 
          await loading.present();
          let httpOptions = {
           headers: new HttpHeaders({
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${this.helper.access_token}`
           }) 
         }
         this.http.delete(url, httpOptions)
             .pipe(
               finalize(() => {
                loading.dismiss();
               })
             ).subscribe(response => {
              this.reports.splice(id, 1);
              update();
              report.next(true);
              this.presentToast('Report deleted Successfully!');
             });
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  delete_confirm(id) {
    this.alertController.create({
      header: 'Zebegna',
      message: 'Do you want to delete this device?',
      backdropDismiss: false,
      buttons: [{
        text: 'No',
        role: 'cancel',
        handler: () => {
          console.log('delete device prevented!');
        }
      }, {
        text: 'Yes',
        handler: async () => {
        const ID = this.devices[id].id;  
        const url = `${environment.device_api}/${ID}`;
        const loading = await this.loadingCtrl.create({
        message: 'Deleting device...',
        }); 
        await loading.present();

        let httpOptions = {
        headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.helper.access_token}`
       }) 
     }
    
      this.http.delete(url, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
           ).subscribe(res => {
            this.devices.splice(id, 1);
            console.log(this.devices)
            device_update();
            get_devices.next(true);
            this.presentToast('Device deleted Successfully!');
         });
         }
       }]
     })
      .then(alert => {
        alert.present();
      });
  }

  async set_fuel_price(price_data: { price, currency, date }, userId) {
    const url = `${environment.fuelprice_api}/${userId}`;
    const loading = await this.loadingCtrl.create({
      message: 'Updating price...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }

    this.http.put(url, price_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            fuel_price_set.next(true);
            this.presentToast('Price updated Successfully!');
          } else {
            this.presentToast('Update price failed. Please try again');
          }
        });
   }

   get_fuel = async (device_id, from, to) => { 
    const url = `${environment.fuel_api}/${device_id},${from},${to}`;
    const loading = await this.loadingCtrl.create({
      message: 'Retriving fuel history...',
    }); 
    await loading.present();
    let httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${this.helper.access_token}`
     }) 
   }
   this.http.get(url, httpOptions)
       .pipe(
         finalize(() => {
           loading.dismiss();
         })
       ).subscribe(response => {
          this.fuel_history.next(response);
       });
   }

   get_users (super_id): Observable<any> {
    const url = `${environment.users_api}/${super_id}`;
    var self = false 
    let httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${this.helper.access_token}`
     }) 
   }
   return this.http.get(url, httpOptions)
       //.pipe(
         //finalize(() => {
          //if(!self)this.users.push(this.helper.login_data);
         //})
       //).subscribe(response => {
         // self = true;
          //this.users = response;
          //this.users.push(this.helper.login_data);
          //this.users.reverse();
          //for(var i = 0; i<this.users.length; i++) this.users[i].rdate = new Date(this.users[i].rdate).toUTCString().split('GMT')[0] + 'EAT';
          //users_table();
       //});
   }

   async create_user(user_data: {superId, name, role, email, username, password, language, phone, expiration, permissions, date}) {
    const url = `${environment.users_api}`;
    const loading = await this.loadingCtrl.create({
      message: 'Creating user...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.post(url, user_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            val.rdate = new Date(val.rdate).toUTCString().split('GMT')[0] + 'EAT';
            val.expiration = val.expiration + '.000Z';
            this.users.reverse();
            this.users.push(val);
            this.users.reverse();
            user_update();
            users_table();
            this.presentToast('User created Successfully!');
          } else {
            this.presentToast('Create user failed. Please try again');
          }
        });
   }


   delete_user(id) {
    this.alertController.create({
      header: 'Zebegna',
      message: 'Do you want to delete this user?',
      backdropDismiss: false,
      buttons: [{
        text: 'No',
        role: 'cancel',
        handler: () => {
          console.log('delete user prevented!');
        }
      }, {
        text: 'Yes',
        handler: async () => {
        const ID = this.users[id].id;  
        const url = `${environment.users_api}/${ID}`;
        const loading = await this.loadingCtrl.create({
        message: 'Deleting user...',
        }); 
        await loading.present();

        let httpOptions = {
        headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.helper.access_token}`
       }) 
     }
    
      this.http.delete(url, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
           ).subscribe(res => {
            this.users.splice(id, 1);
            user_update();
            users_table();
            this.presentToast('User deleted Successfully!');
         });
         }
       }]
     })
      .then(alert => {
        alert.present();
      });
  }

  async update_user(user_data: {superId, name, role, email, username, password, language, phone, expiration, permissions, date}, id){
    const url = `${environment.users_api}/${id}`;
    const loading = await this.loadingCtrl.create({
      message: 'Updating user...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.put(url, user_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            for(let i = 0; i < this.users.length; i++) if(this.users[i].id == val.id) this.users[i] = val;
            this.presentToast('User Updated Successfully!');
          } else {
            this.presentToast('update user failed. Please try again');
          }
        });   
  }

  async create_driver(driver_data: {userid, name, lastname, phone, address, username, password, note, rdate, vehicle}) {
    const url = `${environment.driver_api}`;
    const loading = await this.loadingCtrl.create({
      message: 'Creating driver...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.post(url, driver_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            val.rdate = new Date(val.rdate).toUTCString().split('GMT')[0] + 'EAT';
            this.drivers.reverse();
            this.drivers.push(val);
            this.drivers.reverse();
            driver_update();
            driver_table();
            this.presentToast('Driver created Successfully!');
          } else {
            this.presentToast('Create driver failed. Please try again');
          }
        });
   }

   get_drivers (super_id): Observable<any> {
    const url = `${environment.driver_api}/${super_id}`;
    let httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${this.helper.access_token}`
     }) 
   }
   return this.http.get(url, httpOptions)
   }

   async update_driver(driver_data: { name, lastname, phone, address, username, note, vehicle}, id){
    const url = `${environment.driver_api}/${id}`;
    const loading = await this.loadingCtrl.create({
      message: 'Updating driver...',
    }); 
    await loading.present();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.helper.access_token}`
      }) 
    }
    
    this.http.put(url, driver_data, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
        ).subscribe(res => {
          const val = JSON.parse(JSON.stringify(res));
          if(val.id != undefined ){
            for(let i = 0; i < this.drivers.length; i++) if(this.drivers[i].id == val.id) this.drivers[i] = val;
            this.presentToast('Driver Updated Successfully!');
          } else {
            this.presentToast('update driver failed. Please try again');
          }
        });   
  }

  delete_driver(id) {
    this.alertController.create({
      header: 'Zebegna',
      message: 'Do you want to delete this driver?',
      backdropDismiss: false,
      buttons: [{
        text: 'No',
        role: 'cancel',
        handler: () => {
          console.log('delete driver prevented!');
        }
      }, {
        text: 'Yes',
        handler: async () => {
        const ID = this.drivers[id].id;  
        const url = `${environment.driver_api}/${ID}`;
        const loading = await this.loadingCtrl.create({
        message: 'Deleting user...',
        }); 
        await loading.present();

        let httpOptions = {
        headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.helper.access_token}`
       }) 
     }
    
      this.http.delete(url, httpOptions)
        .pipe(
          finalize(() => {
            loading.dismiss();
          })
           ).subscribe(res => {
            this.drivers.splice(id, 1);
            driver_update();
            driver_table();
            this.presentToast('Driver deleted Successfully!');
         });
         }
       }]
     })
      .then(alert => {
        alert.present();
      });
  }
}

