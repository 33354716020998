import { Subject } from "rxjs";
export var reload = new Subject();
export var click_marker = new Subject();
export var info = new Subject();
export var report = new Subject();
export var get_devices = new Subject();
export var fuel_price_set = new Subject();

click_marker.subscribe(res =>{
    $('#fleetmodal').modal('show');
    info.next(res);
});

fuel_price_set.subscribe(res =>{ if(res) $('#addFuel').modal('hide'); })

report.subscribe(res => {
  if(res)
  $(document).ready(function() {
    var table = $('#ReportdataTable').DataTable({
    responsive: true,
    "autoWidth": false,
    lengthChange: false,
    buttons: [ 'colvis' ],
    language: {
            buttons: {
                colvis: 'Filter Report'
            }
        },
        "aaSorting": []
    });
    table.buttons().container()
        .appendTo( '#ReportdataTable_wrapper .col-md-6:eq(0)');
     });
  });

  get_devices.subscribe(res => {
    //Datatable Js
    $(document).ready(function () {
      $('#DriverdataTable').DataTable({
        "autoWidth": false
      });
      });
  })

  export function driver_table () {
   //Driver Datatable js
  $(document).ready(function () {
    $('#DriverdataTable1').DataTable({
      "autoWidth": false
    });
  });
  }
  
  //update driver table
  export function driver_update(){
    if ($.fn.dataTable.isDataTable('#DriverdataTable1')) {
      $('#DriverdataTable1').DataTable().destroy();
  }
  }    
      

  export function update(){
    if ($.fn.dataTable.isDataTable('#ReportdataTable')) {
        $('#ReportdataTable').DataTable().destroy();
    }
}

export function device_update(){
  if ($.fn.dataTable.isDataTable('#DriverdataTable')) {
    $('#DriverdataTable').DataTable().destroy();
}
}

export function user_update(){
  if ($.fn.dataTable.isDataTable('#UserdataTable')) {
    $('#UserdataTable').DataTable().destroy();
}
}

export function users_table(){
  $(document).ready(function () {
    $('#UserdataTable').DataTable({
      "autoWidth": false
    });
    });  
}

export function close_report(){
    $('#addReport').modal('hide');
}



//$('#myModal').on("hide.bs.modal", function() {
//	alert("clesn up!")
//})

export function start_up(){
$('[data-bs-toggle="tooltip"]').tooltip();    
}

//Overlays Started
export function openNav() {
    document.getElementById("Vehicles").style.width = "100%";
  }
  
export function closeNav() {
    document.getElementById("Vehicles").style.width = "0%";
  }
  
export  function openDash() {
    document.getElementById("Dashboard").style.width = "100%";
  }
  
export function closeDash() {
    document.getElementById("Dashboard").style.width = "0%";
  }
  
export function openUser() {
    document.getElementById("Users").style.width = "100%";
  }
  
export function closeUser() {
    document.getElementById("Users").style.width = "0%";
  }
  
export function openReport() {
    document.getElementById("Reports").style.width = "100%";
  }
  
export function closeReport() {
    document.getElementById("Reports").style.width = "0%";
  }

export function openDriver() {
    document.getElementById("Drivers").style.width = "100%";
  }
  
export function closeDriver() {
    document.getElementById("Drivers").style.width = "0%";
  }  