// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  device_api     : 'http://157.245.147.142:8080/api/device',
  driver_api     : 'http://157.245.147.142:8080/api/driver',
  user_device    : 'http://157.245.147.142:8080/api/user_device',
  position_api   : 'http://157.245.147.142:8080/api/position',
  report_api     : 'http://157.245.147.142:8080/api/report',
  fuelprice_api  : 'http://157.245.147.142:8080/api/fuelprice',
  fuel_api       : 'http://157.245.147.142:8080/api/fuel',
  users_api      : 'http://157.245.147.142:8080/api/users',
  authentication : 'http://157.245.147.142:8080/api/auth',
  refresh        : 'http://157.245.147.142:8080/api/refresh',
  google_api     : 'https://maps.googleapis.com/maps/api/js?v=3.exp&amp;key=AIzaSyDVX66proSjKpol37XaUa37Kr_EM4BrOXM'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
